import request from 'network/request';

const baseURL = '/order/po-member-order/';

export function getOrderList(params) {
  return request({
    url: baseURL + 'get-page',
    method: 'get',
    params
  });
}
export function getShoppingList(params) {
  return request({
    url: '/order/po-online-order/get-detail-page',
    method: 'get',
    params
  });
}
export function exportListing(params) {
  return request({
    url: baseURL + 'get-detail-list/export',
    method: 'get',
    params,
    responseType: 'blob'
  });
}
export function buy(params) {
  return request({
    url: baseURL + 're-purchase',
    method: 'get',
    params
  });
}
export function getInvoiceList(params) {
  return request({
    url: '/order/direct_after_sale/po_can_invoice_order',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  });
}
export function getInvoiceHistoryList(params) {
  return request({
    url: '/order/em-invoice/get-offline-invoice',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  });
}
export function enterpriseOpenInvoice(params) {
  return request({
    url: '/order/em-invoice/insert-invoice',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  });
}
export function getOpenInvoiceDetail(params) {
  return request({
    url: '/order/em-invoice/get-invoice/' + params,
    method: 'get'
  });
}
export function getAssociatedOrder(params) {
  return request({
    url: '/order/direct_after_sale/invoiced_order_list',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  });
}
export function getOrderDetail(params) {
  return request({
    url: '/order/eo-online-order/get-order-detail',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  });
}
export function getLogisticsInfo(params) {
  return request({
    url: '/order/direct_sale_client/get_route_info',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  });
}
