<template>
  <section class="invoice_history">
    <NavBar />
    <Header type="personalCenter" />
    <div class="content box pt30">
      <div class="current_location">当前位置：发票中心-开票历史</div>
      <div class="list mt30">
        <div class="item p30 frsbc" v-for="item in invoiceHistoryList" :key="item.billNo">
          <div class="left">
            <div class="order_no frfsc">
              开票单号：<span>{{item.billNo}}</span><img src="~assets/images/electronic-invoice.png">
            </div>
            <div class="order_amount mt15">
              订单金额：<span>{{item.invMoney}}元</span>
            </div>
          </div>
          <div class="right" @click="openInvoiceDetail(item.billNo)">开票详情</div>
        </div>
      </div>
      <div class="no_invoice" v-if="isNoInvoice">
        <img src="~assets/images/no-history.png">
      </div>
      <Pagination :pageIndex.sync="paging.pageIndex" :pageSize.sync="paging.pageSize" :total="paging.total" @change="getInvoiceHistoryList" v-if="!isNoInvoice" />
    </div>
  </section>
</template>

<script>
  import NavBar from 'components/NavBar/index.vue';
  import Header from 'components/Header/index.vue';
  import Pagination from 'components/Pagination/index.vue';
  import {
    getInvoiceHistoryList
  } from 'api/personalCenter.js';

  export default {
    components: {
      NavBar,
      Header,
      Pagination
    },

    created() {
      this.getInvoiceHistoryList();
    },

    data() {
      return {
        paging: {
          pageIndex: 1,
          pageSize: 10,
          total: 0
        },
        invoiceHistoryList: [],
        isNoInvoice: false
      }
    },

    methods: {
      getInvoiceHistoryList() {
        getInvoiceHistoryList({
          pageIndex: this.paging.pageIndex,
          pageSize: this.paging.pageSize
        }).then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          if(res.data.records.length === 0) {
            this.isNoInvoice = true;
          }else {
            this.isNoInvoice = false;
          }
          this.invoiceHistoryList = res.data.records;
          this.paging.total = res.data.total;
        });
      },
      openInvoiceDetail(billNo) {
        this.$router.push({
          name: 'openInvoiceDetail',
          params: {
            billNo
          }
        });
      }
    }
  }
</script>

<style lang="less" scoped>
  .invoice_history {
    .content {
      .current_location {
        font-size: var(--fontSize16);
        color: var(--color000);
        font-weight: 400;
      }
      .list {
        .item {
          margin-bottom: 15px;
          width: 100%;
          border: 1px solid var(--colore6e6e6);
          .left {
            .order_no, .order_amount {
              font-size: var(--fontSize16);
              color: var(--color333);
              font-weight: 400;
              span {
                font-size: var(--fontSize16);
                color: var(--color000);
                font-weight: bold;
              }
              img {
                width: 68px;
                height: 20px;
              }
            }
            .order_amount {
              span {
                font-size: var(--fontSize16);
                color: var(--colorfe1818);
                font-weight: bold;
              }
            }
          }
          .right {
            font-size: var(--fontSize16);
            color: var(--color1890ff);
            font-weight: bold;
            cursor: pointer;
          }
        }
      }
      .no_invoice {
        margin: 100px auto 0;
        width: 306px;
        height: 196px;
      }
    }
  }
</style>
