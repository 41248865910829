<template>
  <section class="pagination">
    <el-pagination layout="total, sizes, prev, pager, next, jumper" :total="total" :current-page="pageIndex" :page-size="pageSize" @current-change="currentChange" @size-change="sizeChange"></el-pagination>
  </section>
</template>

<script>
  export default {
    props: {
      total: {
        type: Number,
        default: 0
      },
      pageIndex: {
        type: Number,
        default: 0
      },
      pageSize: {
        type: Number,
        default: 0
      }
    },

    methods: {
      currentChange(pageIndex) {
        this.$emit('update:pageIndex', pageIndex);
        this.$emit('change');
      },
      sizeChange(pageSize) {
        this.$emit('update:pageSize', pageSize);
        this.$emit('change');
      }
    }
  }
</script>

<style lang="less" scoped>
  .pagination {
    margin: 30px 0 50px;
  }
</style>
